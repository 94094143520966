import React, { useState, useEffect } from 'react';
import { Container, Button, Row, Col, Alert } from 'react-bootstrap';
import { getImages } from './services/minio';
import './App.css';

function App() {
  const [images, setImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [noMoreImages, setNoMoreImages] = useState(false);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const imageList = await getImages();
        shuffleImages(imageList);
      } catch (error) {
        console.error('Error fetching images', error);
      }
    };

    fetchImages();
  }, []);

  const shuffleImages = (imageList) => {
    const shuffled = imageList.sort(() => 0.5 - Math.random());
    setImages(shuffled);
    setCurrentIndex(0); // Reset to the first image
    setNoMoreImages(false); // Reset the no more images flag
  };

  const showNextImage = () => {
    if (currentIndex < images.length - 1) {
      setCurrentIndex(currentIndex + 1);
    } else {
      setNoMoreImages(true);
    }
  };

  const showPreviousImage = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      setNoMoreImages(false); // Ensure the no more images flag is reset
    }
  };

  return (
    <Container fluid className="text-center mt-5 full-screen-container">
      <Row className="justify-content-center">
        <Col xs={12}>
          <h1>CNCF Loteria</h1>
        </Col>
      </Row>
      {images.length > 0 ? (
        <Row className="justify-content-center mt-3">
          <Col xs={12}>
            {noMoreImages ? (
              <Alert variant="info">
                <p>There's no more images</p>
              </Alert>
            ) : (
              <div className="full-screen-card">
                <img
                  src={images[currentIndex]}
                  alt={`image-${currentIndex}`}
                  className="img-fluid full-screen-image"
                />
                <div className="image-counter">
                  {currentIndex + 1}/{images.length}
                </div>
              </div>
            )}
          </Col>
          {!noMoreImages && (
            <Col xs={12} className="mt-3">
              <Button variant="primary" onClick={showPreviousImage} className="mr-2">
                Previous
              </Button>
              <Button variant="primary" onClick={showNextImage}>
                Next
              </Button>
            </Col>
          )}
        </Row>
      ) : (
        <Row>
          <Col>
            <p>Loading images...</p>
          </Col>
        </Row>
      )}
    </Container>
  );
}

export default App;
