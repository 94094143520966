// src/services/minio.js
import axios from 'axios';

const API_URL = 'https://backend-cncf-loteria.ol.itmlabs.io'; // Replace with your MinIO API URL

export const getImages = async () => {
  try {
    const response = await axios.get(`${API_URL}/list-images`); // Endpoint to list images
    return response.data;
  } catch (error) {
    console.error('Error fetching images', error);
    throw error;
  }
};
